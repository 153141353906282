@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face { 
  font-weight: normal; 
  font-style: normal; 

  font-family: 'noigrotesk'; 
  src: url('../public/fonts/NoiGrotesk-Regular.eot');
  src: url('../public/fonts/NoiGrotesk-Regular.eot') format('embedded-opentype'), 
       url('../public/fonts/NoiGrotesk-Regular.woff2') format('woff2'), 
       url('../public/fonts/NoiGrotesk-Regular.woff') format('woff'), 
       url('../public/fonts/NoiGrotesk-Regular.ttf') format('truetype');
}

body {
  background-color: #ffffff;
  color: #000000;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: disc;
  padding-left: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.js-splitText-line, .js-heroTitle-line, .hero-title-line {
  margin-bottom: 0.1em;
}
.input-phone-number input {
  outline: none !important; 
  border: none !important; 
  padding: 20px;
}

:root {
  --primary-50: #F7FFF0;
  --primary-100: #F0FFE0;
  --primary-200: #E3FFC7;
  --primary-300: #D4FFA8;
  --primary-400: #C7FF8F;
  --primary-500: #B8FF71;
  --primary-600: #94FF29;
  --primary-700: #6EDB00;
  --primary-800: #4A9400;
  --primary-900: #244700;


  --secondary-50: #FDFDFC;
  --secondary-100: #FBFBF9;
  --secondary-200: #F6F7F2;
  --secondary-300: #F4F6EF;
  --secondary-400: #EFF2E8;
  --secondary-500: #EBEEE2;
  --secondary-600: #C3CCA8;
  --secondary-700: #9BAA6E;
  --secondary-800: #687444;
  --secondary-900: #343A22;

  --contrast: #000000;
}



