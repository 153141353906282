.faq-block h1 {
  font-size: 22px;
  margin-bottom: 8px;
  margin-top: 12px;
}

.faq-block h2 {
  font-size: 18px;
  margin-bottom: 8px;
  margin-top: 12px;
}

.faq-block p {
  margin-bottom: 1rem;
}