.helpSection-gradient.active {
  animation: shine 5s ease-in-out infinite;
}

@keyframes shine {
  0% {transform:translateX(-100%);}
	20% {transform:translateX(200%);}
	100% {transform:translateX(200%);}
}

.img-inner {
  --y: 0%;

  clip-path: polygon(0% var(--y), 100% var(--y), 100% 100%, 0% 100%);
}

.blockquote-box {
  --blockquote-box: 0;

  clip-path: polygon(0% 0%, calc(var(--blockquote-box) - 10%) 0%, var(--blockquote-box) 50%, calc(var(--blockquote-box) - 10%) 100%, 0% 100%);
}