
.GoogleMapsInput {
  position: relative;
}

.GoogleMapsInput-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px rgba(2,2,2,0.15) solid;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.23);
  margin-top: 0.4285714286em;
  width: calc(100% + 2px);
  z-index: 999;
}

.GoogleMapsInput-dropdown ul {
  padding: 0;
  margin: 0;
}

.GoogleMapsInput-dropdown li {
  list-style: none;
  padding: 12px 10px;
}

.GoogleMapsInput-header {
  color: #737373;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3em;
  margin: 0;
  text-transform: uppercase;
  padding: 10px;
}

.GoogleMapsInput-footer {
  background-color: rgba(0,0,0,0.02);
  padding: 10px;
  position: relative;
  z-index: 4;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.GoogleMapsInput-footer svg {
  display: block;
}