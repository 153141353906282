.blockContent h2 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.blockContent h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.blockContent h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}