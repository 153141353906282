@import './globals.css';
@import './landing.css';
@import './listing.css';
@import './accordionItem.css';
@import './multiRangeSlider.css';
@import './reactDatepicker.css';
@import './faq.css';
@import './blockContent.css';
@import './googleMapsInput.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

select:not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='24' viewBox='0 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2854 0.858398L9.25738 0.858398L9.25737 18.3564C8.11338 15.1064 4.55138 13.1824 0.989376 13.0004L0.989375 15.2364C5.64338 15.5224 8.81538 18.6684 9.36137 23.0624L11.2074 23.0624C11.7534 18.6684 14.9774 15.3924 19.5794 15.2364L19.5794 13.0004C16.0174 13.1824 12.5334 15.1064 11.2854 18.3564L11.2854 0.858398Z' fill='black'/%3E%3C/svg%3E%0A");
  background-size: 14px;
  background-position: right center;
}


@layer utilities {

  .clip-arrow {

      clip-path: polygon(0 0, calc(100% - 500px) 0, 100% 50%, calc(100% - 500px) 100%, 0 100%);

  }

}