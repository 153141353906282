.xxx-accordionItem-panel-active :is(li, p) {
  animation: 0.5s inUp ease-out
}

.xxx-accordionItem-panel-active ul li:nth-child(2) { animation-delay: 0.3s; }
.xxx-accordionItem-panel-active ul li:nth-child(3) { animation-delay: 0.6s; }
.xxx-accordionItem-panel-active ul li:nth-child(4) { animation-delay: 0.9s; }
.xxx-accordionItem-panel-active ul li:nth-child(5) { animation-delay: 1.2s; }

@keyframes inUp {
  from { transform: translateY(10px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}