.listing .img-inner {
  --y: 100%;

  clip-path: polygon(0% 0%, 100% 0%, 100% var(--y), 0% var(--y));
}

.swiper-button-prev { 
  background-image: url("data:image/svg+xml,%3Csvg width='36' height='37' viewBox='0 0 36 37' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18 1.0625C8.61116 1.0625 1 8.67366 1 18.0625C1 27.4513 8.61116 35.0625 18 35.0625C27.3888 35.0625 35 27.4513 35 18.0625C35 8.67366 27.3888 1.0625 18 1.0625ZM18 0.0624984C8.05888 0.0624976 2.44269e-06 8.12137 1.57361e-06 18.0625C7.04529e-07 28.0036 8.05887 36.0625 18 36.0625C27.9411 36.0625 36 28.0036 36 18.0625C36 8.12137 27.9411 0.0624993 18 0.0624984Z' fill='white'/%3E%3Cpath d='M29.142 19.0068L29.142 16.9788L11.644 16.9788C14.894 15.8348 16.818 12.2728 17 8.71078L14.764 8.71078C14.478 13.3648 11.332 16.5368 6.938 17.0828L6.938 18.9288C11.332 19.4748 14.608 22.6988 14.764 27.3008L17 27.3008C16.818 23.7388 14.894 20.2548 11.644 19.0068L29.142 19.0068Z' fill='white'/%3E%3C/svg%3E%0A") !important;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: w-resize;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg width='37' height='37' viewBox='0 0 37 37' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.3414 35.0059C27.7302 35.0059 35.3414 27.3947 35.3414 18.0059C35.3414 8.61702 27.7302 1.00586 18.3414 1.00586C8.95253 1.00586 1.34137 8.61702 1.34137 18.0059C1.34136 27.3947 8.95252 35.0059 18.3414 35.0059ZM18.3414 36.0059C28.2825 36.0059 36.3414 27.947 36.3414 18.0059C36.3414 8.06474 28.2825 0.00586426 18.3414 0.00586252C8.40024 0.00586078 0.341368 8.06473 0.341366 18.0059C0.341365 27.947 8.40024 36.0059 18.3414 36.0059Z' fill='white'/%3E%3Cpath d='M7.19937 17.0616L7.19937 19.0896L24.6974 19.0896C21.4474 20.2336 19.5234 23.7956 19.3414 27.3576L21.5774 27.3576C21.8634 22.7036 25.0094 19.5316 29.4034 18.9856L29.4034 17.1396C25.0094 16.5936 21.7334 13.3696 21.5774 8.76758L19.3414 8.76758C19.5234 12.3296 21.4474 15.8136 24.6974 17.0616L7.19937 17.0616Z' fill='white'/%3E%3C/svg%3E%0A") !important;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: e-resize;
}

.swiper-button-next::after, .swiper-button-prev::after {
  content: "";
}