.multiRangeSlider-thumb,
.multiRangeSlider-thumb::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: transparent !important;
}

/* For Chrome browsers */
.multiRangeSlider-thumb::-webkit-slider-thumb {
  background-color:var(--primary-500) !important;
  border: none;
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  height: 24px !important;
  width: 24px !important;
  margin-top: 2px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.multiRangeSlider-thumb::-moz-range-thumb {
  background-color:var(--primary-500) !important;
  border: none;
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  height: 24px !important;
  width: 24px !important;
  margin-top: 2px;
  pointer-events: all;
  position: relative;
}
