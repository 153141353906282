
.o-reactDatepicker .react-datepicker {
  border: none;
}

.o-reactDatepicker .react-datepicker__header {
  background-color: transparent;
  text-align: left;
  border-bottom: none;
}

.react-datepicker__day--selected {
  /* background-color: #B8FF71 !important; */
  background-color: var(--primary-500) !important;
  border-radius: 999px !important;
  /* color: black !important; */
  color: var(--contrast) !important;
}

.react-datepicker__day--selected:hover {
  /* background-color: #B8FF71 !important; */
  background-color: var(--primary-500) !important;
}

.o-reactDatepicker .react-datepicker__day--keyboard-selected, .o-reactDatepicker .react-datepicker__day--keyboard-selected:hover {
  background-color: transparent;
  color: black;
}

.o-reactDatepicker .react-datepicker__day--disabled {
  color: #ccc !important;
}

.o-reactDatepicker .react-datepicker__day {
  font-size: 20px;
  padding: 2px;
  width: 42px;
  margin: 0 0.166rem;
}

.o-reactDatepicker .react-datepicker__day:hover {
  border-radius: 999px;
}

.o-reactDatepicker .react-datepicker__day-name {
  width: 42px;
  color: #D1D5DA;
}

.o-reactDatepicker .react-datepicker__month {
  margin: 0.4rem 0;
}

.o-reactDatepicker .react-datepicker__day-names {
  display: none;
}

.o-reactDatepicker .react-datepicker__current-month {
  font-weight: normal;
  font-size: 20px;
  padding-left: 13px;
}

.o-reactDatepicker .react-datepicker__navigation-icon {
  top: 10px;
}

.o-reactDatepicker .react-datepicker__navigation--previous {
  left: auto;
  right: 50px;
}

.react-datepicker__month-select, .react-datepicker__year-select {
  background-position: right 6px center !important;
}